<template>
<!-- 创建小组 -->
  <div>
    <el-dialog
      :title="type == 'create' ? '创建小组' : '修改小组信息'"
      :visible.sync="dialogVisible"
      append-to-body
      width="1000px"
	   :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="16">
            <el-form-item label="小组名称">
              <el-input
                v-model="form.name"
                placeholder="给自己的小组起个响亮的名字"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小组头像">
              <el-upload
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                accept=".jpg, .jpeg, .png, .gif"
                :data="{ needCut: true, isPublic: true }"
                :headers="{ 'X-Access-Token': token }"
              >
                <img
                  v-if="form.avatar != ''"
                  :src="form.avatar"
                  class="avatar"
                />
                <div v-else class="avatar-uploader">点击上传</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="总刷题数">
            <el-slider v-model="form.targetNum" :max="1000"></el-slider>
            <el-input-number
              size="small"
              v-model="form.targetNum"
            ></el-input-number>
            <span class="tip">（上限1000）</span>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="天数">
            <el-slider v-model="form.targetDay" :max="365"></el-slider>
            <el-input-number
              size="small"
              v-model="form.targetDay"
            ></el-input-number>
            <span class="tip">（上限365）</span>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="小组简介">
            <el-input
              placeholder="一句话介绍自己的小组/300字"
              v-model="form.message"
            >
            </el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="加组权限">
              <el-select placeholder="选择小组状态" v-model="form.isOpen">
                <el-option :value="true" label="公有"></el-option>
                <el-option :value="false" label="私有"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否立即招募组员">
              <el-radio-group v-model="form.isRightnow">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="加组口令">
              <el-input
                type="number"
                placeholder="6位数字口令"
                v-model="form.command"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-foot">
        <el-button
          type="info"
          size="small"
          class="btn text"
          @click="dialogVisible = false"
        >
          取 消
        </el-button>
        <el-button
          type="warning"
          size="small"
          class="btn warn"
          @click="exportClick"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="successDialogVisible"
      width="650px"
      :show-close="false"
      class="success-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div slot="title" class="dialog-title">
        恭喜，「{{ successForm.name }}」小组创建成功
      </div>
      <div class="success-welcome">
        <span class="dialog-user">{{ userInfo && userInfo.username }}</span
        >，组长你好！希望在你的组织监督下， 「{{
          successForm.name
        }}」刷题小组，能够如期完成小组目标
      </div>
      <el-form ref="form1" :model="successForm" label-width="80px">
        <el-form-item label="小组链接">
          <div class="dialog-links">
            <el-input v-model="successForm.link" size="small"> </el-input>
            <el-button
              type="info"
              size="small"
              class="copy-btn"
              @click="copyLinks"
              >复 制</el-button
            >
          </div>
        </el-form-item>
        <el-row :gutter="30" v-if="!successForm.isOpen">
          <el-col :span="12">
            <el-form-item label="加组口令">
              <el-input v-model="successForm.command" size="small"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-foot">
        <el-button type="warning" size="small" class="btn warn" @click="inGroup"
          >进入小组
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createGroup, editGroup } from "../../../service/punchClock.js";
import { mapState } from "vuex";
export default {
  name: "createGroup",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: "create",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      form: {
        name: "",
        avatar: "",
        command: "",
        isOpen: true,
        isRightnow: true,
        targetDay: 30,
        targetNum: 120,
        message: "",
      },
      successDialogVisible: false,
      successForm: {
        link: "",
        command: "",
        isOpen: true,
      },
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.avatar = res.result.accessibleUrl;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    exportClick() {
      console.log(this.form);
      if (this.form.name == "") {
        return this.$message.error("请输入小组名称！");
      }
      if (!this.form.isOpen && this.form.command == "") {
        return this.$message.error("请输入口令！");
      }
      if (this.type == "create") {
        createGroup(this.form).then((res) => {
          if (res.success) {
            this.dialogVisible = false;
            this.successDialogVisible = true;
            this.successForm = res.result;
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        editGroup(this.form).then((res) => {
          if (res.success) {
            this.dialogVisible = false;
            this.$message.success("修改小组信息成功！");
            this.$emit("updateGroup");
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    copyLinks() {
      var input = document.createElement("input"); // 直接构建input
      input.value = this.successForm.link; // 设置内容 需要复制的内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功!");
    },
    inGroup() {
      this.$router.push(`/myGroup?groupId=${this.successForm.id}`);
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("changeCreateVisible", value);
      },
    },
    ...mapState(["userInfo"]),
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.type != "create") {
          this.form = this.data;
        } else {
          this.form = {
            name: "",
            avatar: "",
            command: "",
            isOpen: true,
            isRightnow: true,
            targetDay: 30,
            targetNum: 120,
            message: "",
          };
        }
      }
    },
  },
};
</script>
<style scoped lang="css">
.el-form-item {
  line-height: 60px;
}
::v-deep label.el-form-item__label,
::v-deep .el-form-item__content {
  line-height: 60px !important;
}
.avatar-uploader {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  font-size: 12px;
  color: #8894ab;
  border: 1px solid #e0e0e0;
}
.avatar {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
}
::v-deep .el-slider__runway {
  margin: 28px 0;
}
::v-deep .el-slider {
  width: 60%;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.tip {
  color: #34495e;
  font-size: 12px;
}
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}

.warn {
  background-color: #fa6400 !important;
}
.success-dialog .dialog-title {
  font-size: 18px;
  color: #34495e;
  text-align: center;
  float: unset;
}

.success-welcome {
  font-size: 14px;
  color: #8894ab;
  text-align: center;
}

.dialog-user {
  color: #0075f6;
  text-decoration: underline;
}

.success-dialog /deep/ .el-dialog {
  padding: 0 110px;
}

.success-dialog /deep/ .el-dialog__body {
  padding: 0;
}

.dialog-links {
  display: flex;
  align-items: center;
}

.dialog-links .el-button {
  margin-left: 15px;
}

.success-dialog /deep/ .el-dialog__footer {
  padding-bottom: 50px;
}

.success-dialog /deep/ .el-dialog__header {
  padding-top: 50px;
  padding-bottom: 35px;
}

.success-dialog .el-form-item {
  margin-bottom: 16px;
}

.success-welcome {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 22px;
}
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 700;
  padding: 40px 40px 10px;
}
::v-deep .el-dialog__body {
  padding: 20px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-dialog__footer {
  padding: 10px 20px 30px;
}
::v-deep .el-button + .el-button {
  margin-left: 40px;
}
</style>