<template>
  <!-- 发布动态 -->
  <div>
    <div class="dynamic-form" v-if="userInfo && userInfo.id">
      <el-row class="dynamic-form-item" v-show="!focus">
        <el-col :span="24">
          <el-input
            v-model="commentsa"
            size="small"
            placeholder="写下你的动态..."
            @focus="focusEvent"
          ></el-input>
        </el-col>
      </el-row>
      <div v-show="focus">
        <el-row class="dynamic-form-item">
          <el-col :span="24">
            <el-input
              type="textarea"
              v-model="commentsa"
              :rows="3"
              placeholder="写下你的动态..."
              ref="activityCom"
              :autosize="{ minRows: 3, maxRows: 40 }"
              resize="none"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="min-height: 10px">
          <el-upload
            multiple
            :action="uploadUrl"
            list-type="picture-card"
            :on-success="handleCommentPicSuccess"
            :before-upload="beforeAvatarUpload"
            :limit="9"
            accept=".jpg, .jpeg, .png, .gif"
            :data="{ needCut: true, isPublic: true }"
            :headers="{ 'X-Access-Token': token }"
            :on-remove="removeFile"
          >
            <i class="el-icon-plus" ref="uploadComPic"></i>
          </el-upload>
        </el-row>
        <el-row class="">
          <el-col :span="6">
            <emotions @chooseEmotion="handleEmotion"></emotions>
            <el-button style="padding: 4px" @click="uploadPics">
              <i class="el-icon-picture"></i>
            </el-button>
          </el-col>
          <el-col :span="3" :offset="8">
            <el-checkbox v-model="anonymity">匿名发布</el-checkbox>
          </el-col>
          <el-col :span="7">
            <el-select
              v-model="commentInGroupType"
              :disabled="type == 'inGroup'"
              size="mini"
              ><el-option
                v-if="type == 'default'"
                :value="1"
                label="发布到广场"
              ></el-option>
              <el-option
                v-if="type == 'inGroup'"
                :value="2"
                label="小组可见"
              ></el-option>
              <el-option
                v-if="type == 'default'"
                :value="3"
                label="仅自己可见"
              ></el-option>
            </el-select>
            <el-button
              size="mini"
              class="push-btn"
              @click="publishActivity"
              style="padding: 4px"
              >发 布</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="panel"
      style="overflow-y: auto; height: 900px"
      v-infinite-scroll="load"
    >
      <div class="panel-head clearfix">
        <div class="panel-title left">
          {{ type == "default" ? "广场" : type == "inGroup" ? "小组圈子" : "" }}
        </div>
        <div class="bread-tabs">
          <div class="bread-tabs-head" v-if="type == 'default'">
            <div
              class="bread-tab"
              @click="getGroupCommunication('new')"
              :class="{ active: groupComType == 'new' }"
            >
              最新
            </div>
            <div class="bread-tab-line">/</div>
            <div
              class="bread-tab"
              @click="getGroupCommunication('hot')"
              :class="{ active: groupComType == 'hot' }"
            >
              热门
            </div>
            <div class="bread-tab-line" v-if="userInfo && userInfo.id">/</div>
            <div
              class="bread-tab"
              @click="getGroupCommunication('my')"
              :class="{ active: groupComType == 'my' }"
              v-if="userInfo && userInfo.id"
            >
              我的
            </div>
          </div>
          <!-- <div class="bread-tabs-head" v-else-if="type == 'inGroup'">
            <div
              class="bread-tab"
              @click="getGroupCommunication('group')"
              :class="{ active: groupComType == 'group' }"
            >
              小组
            </div>
          </div> -->
        </div>
      </div>
      <div class="panel-body">
        <div class="bread-tabs-body">
          <div class="team-cells">
            <div
              class="team-cell"
              v-for="(comment, index) in groupComList"
              :key="index"
              @click="jumpDetail(comment)"
            >
              <div class="clearfix">
                <div class="team-cell__hd" v-if="comment.cover != ''">
                  <div class="team-img">
                    <img :src="comment.cover" />
                  </div>
                </div>
                <div
                  class="team-cell__bd"
                  :style="{
                    marginLeft: comment.cover != '' ? '217px' : '0',
                  }"
                >
                  <div class="team-cell-title">
                    <div class="comment-name" style="line-height: 24px">
                      <img
                        v-if="comment.avatar"
                        :src="comment.avatar"
                        style="
                          width: 24px;
                          height: 24px;
                          margin-right: 6px;
                          border-radius: 50%;
                        "
                      />
                      <span style="vertical-align: top"
                        ><span style="font-size: 12px; color: #0075f6">{{
                          comment.username ? comment.username : "匿名用户"
                        }}</span>
                        ·
                        <span style="font-size: 12px; color: #bebebe">{{
                          comment.createTime
                        }}</span></span
                      >
                    </div>
                  </div>
                  <div class="team-cell-desc">
                    {{ comment.content }}
                  </div>
                  <div class="team-cell-info">
                    <div class="comment-user">
                      <div class="comment-number">
                        <i class="iconfont icon-liulan"></i
                        >{{ comment.pageViews }}
                      </div>
                      <div class="comment-number">
                        <i class="el-icon-chat-line-round"></i
                        >{{ comment.answerNum }}
                      </div>
                      <div class="comment-number">
                        <i class="iconfont icon-dianzan"></i
                        >{{ comment.likeNum }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import emotions from "@/utils/emotions/index";
import {
  getGroupCommunication,
  publishActivity,
  getInGroupCommutes,
  getInGroupPrivateCommutes,
  publishInGroupActivity,
  viewActivity,
} from "../../service/punchClock.js";
export default {
  name: "publishComment",
  components: { emotions },
  props: {
    needRange: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    groupId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      groupComType: "",
      groupComList: [],
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      currentPage: 1,
      commentsa: "",
      commentsPic: [],
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      focus: false,
      commentInGroupType: 2,
      anonymity: false,
    };
  },
  methods: {
    getGroupCommunication(type) {
      this.groupComType = type
        ? type
        : this.type == "default"
        ? "new"
        : this.type == "inGroup"
        ? "group"
        : "";
      this.currentPage = 1;
      if (this.type == "default") {
        if (type != "my") {
          getGroupCommunication(this.groupComType).then((res) => {
            if (res.success) {
              console.log(res.result);
              this.groupComList = res.result.records;
            }
          });
        } else if (type == "my") {
          getInGroupPrivateCommutes(1).then((res) => {
            if (res.success) {
              console.log(res.result);
              this.groupComList = res.result.records;
            }
          });
        }
      } else if (this.type == "inGroup") {
        if (!type || type == "group") {
          getInGroupCommutes(this.$route.query.groupId, 1).then((res) => {
            if (res.success) {
              this.groupComList = res.result.records;
            }
          });
        }
      }
    },
    load() {
      if (this.currentPage >= 10) {
        return;
      }
      if (this.type == "default") {
        if (this.groupComType != "my") {
          getGroupCommunication(this.groupComType, this.currentPage + 1).then(
            (res) => {
              if (res.success) {
                this.groupComList = this.groupComList.concat(
                  res.result.records
                );
                this.currentPage = this.currentPage + 1;
              }
            }
          );
        } else if (this.groupComType == "my") {
          getInGroupPrivateCommutes(this.currentPage + 1).then((res) => {
            if (res.success) {
              this.groupComList = this.groupComList.concat(res.result.records);
              this.currentPage = this.currentPage + 1;
            }
          });
        }
      } else if (this.type == "inGroup") {
        if (this.groupComType == "group") {
          getInGroupCommutes(
            this.$route.query.groupId,
            this.currentPage + 1
          ).then((res) => {
            if (res.success) {
              this.groupComList = this.groupComList.concat(res.result.records);
              this.currentPage = this.currentPage + 1;
            }
          });
        }
      }
    },
    handleEmotion(emotion) {
      console.log(emotion);
      this.commentsa = this.commentsa + emotion;
    },
    handleCommentPicSuccess(res) {
      this.commentsPic.push(res.result.accessibleUrl);
    },
    uploadPics() {
      this.$refs.uploadComPic.click();
    },
    beforeAvatarUpload(file) {
      if (this.commentsPic.length >= 9) {
        return this.$message.error("最多只能上传9张图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    removeFile(file, fileList) {
      console.log(file.response.result.accessibleUrl);
      let removeIndex = this.commentsPic.indexOf(
        file.response.result.accessibleUrl
      );
      this.commentsPic.splice(removeIndex, 1);
      console.log(this.commentsPic);
    },
    publishActivity() {
      this.focus = false;
      if (this.commentsa == "") {
        return this.$message.error("动态内容不能为空！");
      }
      if (this.type == "default") {
        const data = {
          content: this.commentsa,
          type: this.commentInGroupType,
          pictures: this.commentsPic,
          anonymity: this.anonymity,
        };
        publishActivity(data).then((res) => {
          if (res.success) {
            this.$message.success("发布动态成功！");
            this.getGroupCommunication();
            this.commentsa = "";
          }
        });
      } else if (this.type == "inGroup") {
        const data = {
          clockInGroupId: [this.$route.query.groupId],
          content: this.commentsa,
          pictures: this.commentsPic,
          type: 2,
        };
        publishInGroupActivity(data).then((res) => {
          if (res.success) {
            this.$message.success("发布动态成功！");
            this.getGroupCommunication();
            this.commentsa = "";
          }
        });
      }
    },
    focusEvent() {
      this.focus = true;
      this.$nextTick(() => {
        this.$refs.activityCom.focus();
      });
    },
    jumpDetail(comment) {
      viewActivity(comment.postsId).then((res) => {
        if (res.success) {
          this.$router.push(`/activityDetail?id=${comment.id}`);
        }
      });
    },
  },
  mounted() {
    this.getGroupCommunication();

    if (this.type == "default") {
      this.commentInGroupType = 1;
    } else {
      this.commentInGroupType = 2;
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
};
</script>
<style scoped>
.panel::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}
.panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.dynamic-form-item .el-button {
  width: 90px;
}

.dynamic-form-item /deep/ .el-textarea__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item /deep/ .el-input__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item + .dynamic-form-item {
  margin-top: 10px;
}

.push-btn {
  background-color: #fa6400;
  border-color: #fa6400;
  color: #fff;
  float: right;
  height: 40px;
}

.dynamic-form-item .el-select {
  width: 125px;
}

.dynamic-form-item /deep/ .el-select .el-input__inner {
  background-color: #fff;
}

.bg-line {
  margin: 0 -40px;
  height: 20px;
  background: #f8f8f8;
}

.dynamic-form {
  padding: 20px;
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
}
::v-deep .el-upload.el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin-top: 8px;
}
.panel-head {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bread-tab {
  display: inline-block;
  padding: 0 2px;
  cursor: pointer;
}

.bread-tab.active {
  color: #fa6400;
}

.bread-tabs-head {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
}

.bread-tabs {
  float: right;
}

.left {
  float: left;
}

.team-img {
  font-size: 0;
}

.team-cell__hd {
  width: 200px;
  margin-right: 17px;
  float: left;
}

.team-cell {
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.team-cell:hover {
  cursor: pointer;
}

.team-cell-title {
  color: #34495e;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
}

.push-time {
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  font-size: 12px;
  margin-left: 12px;
}

.team-cell-desc {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 12px;
  font-size: 16px;
  letter-spacing: 1px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
  margin-left: 217px;
}
.team-cell-info {
  float: right;
}
.comment-user {
  display: flex;
}
.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 25px;
  font-size: 16.8px;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 16.8px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__block .el-textarea /deep/ .el-textarea__inner {
  background: rgba(235, 236, 241, 0.43);
  border-radius: 2px;
  border: 1px solid #979797;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}
.panel-title {
  position: relative;
  font-weight: 700;
  color: #34495e;
  font-size: 16px;
  padding-left: 14px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}

.panel {
  padding: 0 30px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 4px;
}

.bread-tab-line {
  display: inline-block;
  padding: 0 5px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 96px;
  height: 96px;
}
::v-deep .el-input--mini .el-input__inner {
  height: 22px;
  line-height: 22px;
}
::v-deep .el-input--mini .el-input__icon {
  line-height: 22px;
}
</style>